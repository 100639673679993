/* LicensePage.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #1a1a1a;
  color: #fff;
}

.license-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  width: 300px;
  font-size: 16px;
}

button {
  padding: 10px;
  background-color: #ffa500;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #e59400;
}
